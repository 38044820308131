import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { Paginator } from 'primereact/paginator';
import Header from './header';
import Sidebar from './sidebar';
import '../App.css'
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [deliveryData, setDeliveryData] = useState('');
    const [newDeliveryData, setNewDeliveryData] = useState([]);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState('');

    const getDelivery = async () => {
        try{
            const response = await axios.get('https://demo.sninfoserv.com/api/shipping/');
            console.log(response.data);
            if(response.data.status === 'success'){
               setDeliveryData(response.data.data);
            }
          }catch(error){
    
          }
    };

    useEffect(() => {
        if(deliveryData.length > 0 ){
            deliveryData.forEach(element => {
                newDeliveryData.push(element);
            });
        }else{
            getDelivery();
        }
    },[deliveryData]);

    
    console.log(newDeliveryData);
    

    return (
        <> 
        <Sidebar />
            <div class="body-wrapper">
            <Header />
                <div class="body-wrapper-inner">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 d-flex align-items-stretch">
                                <div class="card w-100">
                                    <div class="card-body p-4">
                                        <h5 class="card-title fw-semibold mb-4">Delivery Charges</h5>
                                   
                                    <div class="row mt-4">
                                    <div class="table-responsive" data-simplebar>
                    <table class="table text-nowrap align-middle table-custom mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="text-dark fw-normal">Weight</th>
                          <th scope="col" class="text-dark fw-normal">India</th>
                          <th scope="col" class="text-dark fw-normal">USA</th>
                          <th scope="col" class="text-dark fw-normal">Canada</th>
                          <th scope="col" class="text-dark fw-normal">UK</th>
                          <th scope="col" class="text-dark fw-normal">Australia</th>
                          <th scope="col" class="text-dark fw-normal">New Zealand</th>
                          <th scope="col" class="text-dark fw-normal">Germany</th>
                          <th scope="col" class="text-dark fw-normal">Netherland</th>
                          <th scope="col" class="text-dark fw-normal">Denmark</th>
                          <th scope="col" class="text-dark fw-normal">France</th>
                          <th scope="col" class="text-dark fw-normal">Italy</th>
                          <th scope="col" class="text-dark fw-normal">Poland</th>
                          <th scope="col" class="text-dark fw-normal">Sweden</th>
                          <th scope="col" class="text-dark fw-normal">Ireland</th>
                          <th scope="col" class="text-dark fw-normal">Belgium</th>
                          <th scope="col" class="text-dark fw-normal">Portugal</th>
                          <th scope="col" class="text-dark fw-normal">Singapore</th>
                          <th scope="col" class="text-dark fw-normal">Dubai</th>
                          <th scope="col" class="text-dark fw-normal">Malaysia</th>
                        </tr>
                      </thead>
                      <tbody>
                      {deliveryData && deliveryData.map((charge, index) =>
                        <tr key={index}>
                          <td>
                          <span class="badge bg-success-subtle text-success">{index+1} KG</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.ind}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.usa}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.can}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.uk}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.aus}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.nz}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.ger}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.net}</span>
                          </td>
                        
                          <td>
                            <span class="text-dark">₹ {charge.den}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.fra}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.ita}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.pol}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.swe}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.ire}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.bel}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.por}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.sin}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.dub}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {charge.mal}</span>
                          </td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                                        

                                    </div>
                               
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default App;