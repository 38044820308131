import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { Paginator } from 'primereact/paginator';
import Header from './header';
import Sidebar from './sidebar';
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [orderData, setOrderData] = useState('');
    const [error, setError] = useState('');
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(5);

    useEffect(()=>{
      if(!userId){
        window.location ='/';
      }
    },[]);

    const getOrderDetails = async () => {
        try{
          const response = await axios.get('https://demo.sninfoserv.com/api/orders/all-orders/');
          console.log(response.data);
          if(response.data.status === 'success'){
            setOrderData(response.data.orderData);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getOrderDetails();
    },[]);
    
    const onPageChange = (event) => {
      setFirst(event.first);
      setRows(5);
    };

    return (
      <>
      <Sidebar />
        <div class="body-wrapper">
         <Header />
         <div class="body-wrapper-inner">
          <div class="container-fluid">
           
            <div class="row">
              <div class="col-lg-12 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Orders</h5>
                    <div class="table-responsive" data-simplebar>
                      <table class="table text-nowrap align-middle table-custom mb-0">
                        <thead>
                          <tr>
                            <th scope="col" class="text-dark fw-normal ps-0">Customer</th>
                            <th scope="col" class="text-dark fw-normal">Order ID</th>
                            <th scope="col" class="text-dark fw-normal">Date</th>
                            <th scope="col" class="text-dark fw-normal">Amount</th>
                            <th scope="col" class="text-dark fw-normal">Items</th>
                            <th scope="col" class="text-dark fw-normal">Country</th>
                            <th scope="col" class="text-dark fw-normal">View</th>
                          </tr>
                        </thead>
                        <tbody>
                        {orderData && orderData.slice().reverse().slice(first, first + rows).map((orders, index) =>
                          <tr key={index}>
                            <td class="ps-0">
                              <div class="d-flex align-items-center gap-6">
                                <img src="assets/images/profile/user-1.jpg" alt="prd1" width="48"
                                  class="rounded" />
                                <div>
                                  <h6 class="mb-0">{orders.billingData.fName} {orders.billingData.lName}</h6>
                                  <span>{orders.billingData.email}</span>
                                </div>
                              </div>
                            </td>
                            <td> <span class="text-dark">{orders.orderId}</span> </td>
                            <td> <span class="text-dark">{orders.date}</span> </td>
                            <td> <span class="text-dark">₹ {Number(orders.grandTotal).toFixed(2)}</span> </td>
                            <td> <span class="badge bg-success-subtle text-success">{orders.Items}</span> </td>
                            <td> <span class="text-dark">{orders.shippingData.country}</span> </td>
                            <td> <a class="btn btn-light m-1" href={`order-info?id=${orders.orderId}`}>More</a> </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                    <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={orderData ? orderData.length : 0} onPageChange={onPageChange} />
                  </div>
                </div>
              </div>
            </div>

            
            
            <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>
            
            <div id="revenue-forecast" style={{display: 'none'}}></div>
            <div id="total-income" style={{display: 'none'}}></div>
            
          </div>
        </div>
       </div>
      </>
    );
  }
  
  export default App;
  