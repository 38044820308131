import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

function App() {

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const extractProductId = () => {
        const url = window.location.href; 
        const queryString = url.split('?')[1] || ''; 
        const params = new URLSearchParams(queryString);
        const logout = params.get('id');
        if(logout){
          sessionStorage.removeItem('jmpiadminUserId');
        }
    };

    useEffect(() => {
        extractProductId();
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const Login = async (e) => {
        e.preventDefault();
        
        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/admin_panel/login/', {email: email, pass: password});
            console.log(response.data);
            if(response.data.status === 'success'){
              sessionStorage.setItem('jmpiadminUserId', response.data.userId);
              sessionStorage.setItem('jmpiadminUserEmail', response.data.userEmail);
              sessionStorage.setItem('jmpiadminUserName', response.data.userName);
              window.location ='/dashboard';
            }else{
                setError(response.data.message);
            }

        }catch(error){

        }
    };



  return (

    <div class="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
    <div class="position-relative overflow-hidden text-bg-light min-vh-100 d-flex align-items-center justify-content-center">
      <div class="d-flex align-items-center justify-content-center w-100">
        <div class="row justify-content-center w-100">
          <div class="col-md-6 col-lg-6 col-xxl-6">
            <div class="card mb-0">
              <div class="card-body">
                <a href="/" class="text-nowrap logo-img text-center d-block py-3 w-100">
                  <img src="favicon.ico" alt="Jampani Pickles" />
                </a>
                <p class="text-center">Welcome to Japmapi Pickles</p>
                <form onSubmit={Login}>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">Username</label>
                    <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" value={email} onChange={(e)=>{setEmail(e.target.value)}} />
                  </div>
                  <div class="mb-4">
                    <label for="exampleInputPassword1" class="form-label">Password</label>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        id="exampleInputPassword1"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ paddingRight: '20px' }} 
                    />
                    <span
                        className="position-absolute"
                        onClick={togglePasswordVisibility}
                        style={{
                            right: '40px',
                            top: '63%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            pointerEvents: 'auto',
                        }}
                    >
                        <iconify-icon icon={showPassword ? 'fa6-regular:eye' : 'mingcute:eye-close-fill'} />
                    </span>
                  </div>
                  
                  <button type='submit' class="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2">Sign In</button>
                </form>
                <p class="text-danger text-center">{error.length > 0 && error}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

  );
}
export default App;