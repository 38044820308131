import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { Paginator } from 'primereact/paginator';
import Header from './header';
import Sidebar from './sidebar';
import '../App.css'
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [productData, setProductData] = useState(null);
    const [error, setError] = useState('');
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(5);

    useEffect(()=>{
        if(!userId){
          window.location ='/';
        }
    },[]);

    const getProduct = async () => {
        const url = 'https://demo.sninfoserv.com/api/products/view-products/';
        try {
          const res = await axios.get(url);
          setProductData(res.data.data);
          //console.log(res.data);
        } catch (error) {
            setError({ error: error.message });
        }
    };
      
    useEffect(() => {
        getProduct();
    },[]);

    const onPageChange = (event) => {
        setFirst(event.first);
        setRows(5);
    };

    const deleteProduct = async (pCode) => {
        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/products/delete-product/', {code: pCode});
            if(response.data.status === 'success'){
                getProduct();
            }
        } catch (error) {
            setError({ error: error.message });
        }
    };

    const hideProduct = async (pCode, visible) => {

        let visi = '';
        if(visible === 'yes'){
            visi = 'no';
        }else{
            visi = 'yes';
        }
        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/products/hide-product/', {code: pCode, visiblility: visi});
            if(response.data.status === 'success'){
                getProduct();
            }
        } catch (error) {
            setError({ error: error.message });
        }
    };

    //console.log(productData);

    return (
        <> 
        <Sidebar />
        <div class="body-wrapper">
        <Header />
        <div class="body-wrapper-inner">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Products <a href='/add-product' class='btn btn-primary position-absolute' style={{right:'30px'}}>Add Product</a></h5>
                    <div class="table-responsive" data-simplebar>
                      <table class="table text-nowrap align-middle table-custom mb-0">
                        <thead>
                          <tr>
                            <th scope="col" class="text-dark fw-normal ps-0">Product</th>
                            <th scope="col" class="text-dark fw-normal">Category</th>
                            <th scope="col" class="text-dark fw-normal">Price 250g</th>
                            <th scope="col" class="text-dark fw-normal">Price 500g</th>
                            <th scope="col" class="text-dark fw-normal">More</th>
                          </tr>
                        </thead>
                        <tbody>
                        {productData && productData.slice().reverse().slice(first, first + rows).map((product, index) =>
                          <tr key={index}>
                            <td class="ps-0">
                              <div class="d-flex align-items-center gap-6">
                                <img src={`https://demo.sninfoserv.com/api/products/images/${product.images[0].image}`} alt={product.product} width="48"
                                  class="rounded" />
                                <div>
                                  <h6 class="mb-0">{product.product}</h6>
                                  <span>{product.product_code}</span>
                                </div>
                              </div>
                            </td>
                            <td> <span class="text-dark">{product.product_type}</span> </td>
                            <td> <span class="text-dark">₹ {Number(product.variations[0].price).toFixed(2)}</span> </td>
                            <td> <span class="text-dark">₹ {Number(product.variations[1].price).toFixed(2)}</span> </td>        
                            <td> 
                                <button class={`btn ${product.visibility === 'yes' ? 'btn-success' : 'btn-info'} m-1`} onClick={() => hideProduct(product.product_code, product.visibility)} ><iconify-icon icon={product.visibility === 'yes' ? 'fa6-regular:eye' : 'mingcute:eye-close-fill'} /></button> 
                                <a class="btn btn-warning m-1" href={`edit-product?id=${product.p_id}`}><iconify-icon icon="cuida:edit-outline"></iconify-icon></a> 
                                <button class="btn btn-danger m-1" onClick={() => deleteProduct(product.product_code)} ><iconify-icon icon="uiw:delete"></iconify-icon></button> 
                            </td>
                          </tr>
                        )}
                        </tbody>
                      </table>
                    </div>
                    <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={productData ? productData.length : 0} onPageChange={onPageChange} />
                  </div>
                </div>
              </div>
             
            </div>

            <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>

                </div>
              </div>
            </div>
        </>
    );
}
export default App;