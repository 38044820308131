import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/index';
import OrderPage from './pages/orders';
import OrderInfo from './pages/orderDetails';
import Test from './pages/test';
import Login from './pages/login';
import Combo from './pages/combo';
import AddCombo from './pages/addCombo';
import Products from './pages/products';
import EditProduct from './pages/editProduct';
import AddProduct from './pages/addProduct';
import Delivery from './pages/delivery';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Login />} />
        <Route path="/dashboard" element={<HomePage />} />
        <Route path="/orders" element={<OrderPage />} />
        <Route path="/order-info" element={<OrderInfo />} />
        <Route path="/combo" element={<Combo />} />
        <Route path="/add-combo" element={<AddCombo />} />
        <Route path="/products" element={<Products />} />
        <Route path="/edit-product" element={<EditProduct />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/delivery" element={<Delivery />} />
        <Route path="/test" element={<Test />} />
      </Routes>
    </Router>
  );
}

export default App;