import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Sidebar from './sidebar';
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [comboData, setComboData] = useState('');
    const [error, setError] = useState('');

    useEffect(()=>{
      if(!userId){
        window.location ='/';
      }
    },[]);

    const getComboDetails = async () => {
        try{
          const response = await axios.get('https://demo.sninfoserv.com/api/combo/');
          console.log(response.data);
          if(response.data.status === 'success'){
            setComboData(response.data);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getComboDetails();
    },[]);

    return (
        <> 
            <Sidebar />
            <div class="body-wrapper">
                <Header />
                <div class="body-wrapper-inner">
                <div class="container-fluid">
                  <div class="row">

                    

                  </div>
                </div>
              </div>
            </div>
        </>
    );
}
export default App;