import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Sidebar from './sidebar';
ReactSession.setStoreType("localStorage");

function App() {
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [orderData, setOrderData] = useState('');
    const [error, setError] = useState('');
    const [stats, setStats] = useState('');
    const [tmStats, setTmStats] = useState('');
    
    useEffect(()=>{
      if(!userId){
        window.location ='/';
      }
    },[]);
    

    const getOrderDetails = async () => {
        try{
          const response = await axios.get('https://demo.sninfoserv.com/api/orders/all-orders/');
          //console.log(response.data);
          if(response.data.status === 'success'){
            setOrderData(response.data.orderData);

            const validOrders = response.data.orderData.filter(order => order.status !== 'Cancelled');
            let orderTotal = validOrders.reduce((total, order) => total + Number(order.grandTotal), 0);
            const orderCount = validOrders.length;
            setStats({ totalOrders: orderCount, totalAmount: orderTotal });

            const currentDate = new Date();
            const currentMonth = currentDate.getMonth(); // 0-indexed (0 = January, 11 = December)
            const currentYear = currentDate.getFullYear();

            const thisMonth = validOrders.filter(order => {
              const orderDate = new Date(order.date);
              //console.log(orderDate.getMonth(), currentMonth);
              return orderDate.getMonth() === currentMonth && orderDate.getFullYear() === currentYear;
              
            });

            const tmOrderTotal = thisMonth.reduce((total, order) => total + Number(order.grandTotal), 0);
            const tmOrderCount = thisMonth.length;
            
            const delieverd = thisMonth.filter(order => order.status === 'Delivered');
            const cancelled = thisMonth.filter(order => order.status === 'Cancelled');

            setTmStats({ totalOrders: tmOrderCount, totalAmount: tmOrderTotal, delievered: delieverd.length, cancelled: cancelled.length});
            
            //console.log(thisMonth);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getOrderDetails();
    },[]);

    
    const getRandomNumber = () => {
      return Math.floor(Math.random() * 3) + 1;
    };
    
    //console.log(tmStats);
 
    return (
      <>
      <Sidebar />
        <div class="body-wrapper">
         <Header />
         <div class="body-wrapper-inner">
          <div class="container-fluid">
          <div class="row">
            <div class="col-lg-8 d-flex align-items-strech">
              <div class="card w-100">
                <div class="card-body">
                  <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                    <div class="mb-3 mb-sm-0">
                      <h5 class="card-title fw-semibold">This Month</h5>
                    </div>
                  </div>
                  <div class="row">

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center gap-6 mb-4 pb-3">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-secondary-subtle">
                          <iconify-icon icon="solar:football-outline" class="fs-6 text-secondary"> </iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Total Orders</h6>
                        <h4>{tmStats.totalOrders}</h4>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center gap-6 mb-4 pb-3">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-danger-subtle">
                          <iconify-icon icon="solar:box-linear" class="fs-6 text-danger"></iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Total Amount</h6>
                        <h4>₹ {Number(tmStats.totalAmount).toFixed(2)}</h4>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center gap-6 mb-4 pb-3">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-danger-subtle">
                          <iconify-icon icon="solar:box-linear" class="fs-6 text-danger"></iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Orders Delivered</h6>
                        <h4>{tmStats.delievered}</h4>
                      </div>
                    </div>

                    <div class="col-lg-6">
                      <div class="d-flex align-items-center gap-6 mb-4 pb-3">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-secondary-subtle">
                          <iconify-icon icon="solar:football-outline" class="fs-6 text-secondary"> </iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Orders Cancelled</h6>
                        <h4>{tmStats.cancelled}</h4>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="row">
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center gap-6 mb-4 pb-3">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-secondary-subtle">
                          <iconify-icon icon="solar:football-outline" class="fs-6 text-secondary"> </iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Total Orders</h6>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h4>{stats.totalOrders}</h4>
                        </div>
                        <div class="col-12">
                        </div>
                      </div>
                      {/* <div class="d-flex align-items-center justify-content-between mb-6">
                        <h6 class="mb-0 fw-medium">New goals</h6>
                      </div>
                      <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="25"
                        aria-valuemin="0" aria-valuemax="100" style={{height: '7px'}}>
                        <div class="progress-bar bg-secondary" style={{width: '83%'}}></div>
                      </div> */}
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex align-items-center gap-6 mb-4">
                        <span
                          class="round-48 d-flex align-items-center justify-content-center rounded bg-danger-subtle">
                          <iconify-icon icon="solar:box-linear" class="fs-6 text-danger"></iconify-icon>
                        </span>
                        <h6 class="mb-0 fs-4">Total Income</h6>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <h4>₹ {Number(stats.totalAmount).toFixed(2)}</h4>
                        </div>
                        <div class="col-12">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 d-flex align-items-stretch">
              <div class="card w-100">
                <div class="card-body p-4">
                  <h5 class="card-title fw-semibold mb-4">Recent Orders</h5>
                  <div class="table-responsive" data-simplebar>
                    <table class="table text-nowrap align-middle table-custom mb-0">
                      <thead>
                        <tr>
                          <th scope="col" class="text-dark fw-normal ps-0">Customer</th>
                          <th scope="col" class="text-dark fw-normal">Date</th>
                          <th scope="col" class="text-dark fw-normal">Items</th>
                          <th scope="col" class="text-dark fw-normal">Amount</th>
                          <th scope="col" class="text-dark fw-normal">Status</th>
                          <th scope="col" class="text-dark fw-normal">Country</th>
                        </tr>
                      </thead>
                      <tbody>
                      {orderData && orderData.slice().reverse().slice(-5).map((orders, index) =>
                        <tr>
                          <td class="ps-0">
                            <div class="d-flex align-items-center gap-6">
                              <img src={`assets/images/profile/user-${getRandomNumber()}.jpg`} alt={orders.billingData.fName} width="48"
                                class="rounded" />
                              <div>
                                <h6 class="mb-0">{orders.billingData.fName} {orders.billingData.lName}</h6>
                                <span><small>{orders.billingData.email}</small></span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span>{orders.date}</span>
                          </td>
                          <td>
                            <span class="badge bg-success-subtle text-success">{orders.Items}</span>
                          </td>
                          <td>
                            <span class="text-dark">₹ {Number(orders.grandTotal).toFixed(2)}</span>
                          </td>
						  <td>
                            <span class="text-dark">{orders.status}</span>
                          </td>
                          <td>
                            <span>{orders.shippingData.country}</span>
                          </td>
                        </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4 d-flex align-items-stretch">
              <div class="card w-100">
                <div class="card-body p-4">
                  <div class="mb-4">
                    <h5 class="card-title fw-semibold">Daily activities</h5>
                  </div>
                  <ul class="timeline-widget mb-0 position-relative mb-n5">
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n1 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-primary flex-shrink-0 mt-2"></span>
                        <span class="timeline-badge-border d-block flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n1">Payment received from John
                        Doe of $385.90</div>
                    </li>
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n6 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-warning flex-shrink-0"></span>
                        <span class="timeline-badge-border d-block flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n6 fw-semibold">New sale
                        recorded <a href="javascript:void(0)" class="text-primary d-block fw-normal ">#ML-3467</a>
                      </div>
                    </li>
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n6 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-warning flex-shrink-0"></span>
                        <span class="timeline-badge-border d-block flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n6">Payment was made of $64.95
                        to Michael</div>
                    </li>
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n6 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-secondary flex-shrink-0"></span>
                        <span class="timeline-badge-border d-block flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n6 fw-semibold">New sale
                        recorded <a href="javascript:void(0)" class="text-primary d-block fw-normal ">#ML-3467</a>
                      </div>
                    </li>
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n6 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-danger flex-shrink-0"></span>
                        <span class="timeline-badge-border d-block flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n6 fw-semibold">Project meeting
                      </div>
                    </li>
                    <li class="timeline-item d-flex position-relative overflow-hidden">
                      <div class="timeline-time mt-n6 text-muted flex-shrink-0 text-end">09:46
                      </div>
                      <div class="timeline-badge-wrap d-flex flex-column align-items-center">
                        <span class="timeline-badge bg-primary flex-shrink-0"></span>
                      </div>
                      <div class="timeline-desc fs-3 text-dark mt-n6">Payment received from John
                        Doe of $385.90</div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>


          
		  
		  
		  
          <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
          </div>
		  
		  
        </div>
      </div>
      </div>
		</>
   
  );
}

export default App;
