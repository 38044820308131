

function App() {

  return (
    <aside class="left-sidebar">
      <div>
        <div class="brand-logo d-flex align-items-center justify-content-between">
          <a href="/dashboard" class="text-nowrap logo-img">
            <h5 class="card-title fw-semibold"><img src="favicon.ico" alt="Jampani Pickles" width="30px" />Jampani Pickles</h5>
          </a>
          <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
            <i class="ti ti-x fs-8"></i>
          </div>
        </div>
        
		
        <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
          <ul id="sidebarnav">
            <li class="nav-small-cap">
              <iconify-icon icon="solar:menu-dots-linear" class="nav-small-cap-icon fs-4"></iconify-icon>
              <span class="hide-menu">Home</span>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/dashboard" aria-expanded="false">
                <iconify-icon icon="solar:widget-add-line-duotone"></iconify-icon>
                <span class="hide-menu">Dashboard</span>
              </a>
            </li>
            <li>
              <span class="sidebar-divider lg"></span>
            </li>
            <li class="nav-small-cap">
              <iconify-icon icon="solar:menu-dots-linear" class="nav-small-cap-icon fs-4"></iconify-icon>
              <span class="hide-menu">Orders</span>
            </li>

            <li class="sidebar-item">
              <a class="sidebar-link" href="/orders" aria-expanded="false">
                <iconify-icon icon="solar:layers-minimalistic-bold-duotone"></iconify-icon>
                <span class="hide-menu">Orders</span>
              </a>
            </li>

            <li class="sidebar-item">
              <a class="sidebar-link" href="/combo" aria-expanded="false">
                <iconify-icon icon="solar:bookmark-square-minimalistic-line-duotone"></iconify-icon>
                <span class="hide-menu">Combos</span>
              </a>
            </li>

            <li class="sidebar-item">
              <a class="sidebar-link" href="/products" aria-expanded="false">
              <iconify-icon icon="fluent-mdl2:product-variant"></iconify-icon>
                <span class="hide-menu">Products</span>
              </a>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/delivery" aria-expanded="false">
              <iconify-icon icon="fluent-mdl2:product-variant"></iconify-icon>
                <span class="hide-menu">Delivery Charge</span>
              </a>
            </li>

            
           
            
            <li>
              <span class="sidebar-divider lg"></span>
            </li>
            <li class="nav-small-cap">
              <iconify-icon icon="solar:menu-dots-linear" class="nav-small-cap-icon fs-4"></iconify-icon>
              <span class="hide-menu">AUTH</span>
            </li>
            <li class="sidebar-item">
              <a class="sidebar-link" href="/?id=logout" aria-expanded="false">
                <iconify-icon icon="solar:login-3-line-duotone"></iconify-icon>
                <span class="hide-menu">Logout</span>
              </a>
            </li>
          
          </ul>
         
        </nav>
      
      </div>
     
    </aside>
  );
}

export default App;