import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Sidebar from './sidebar';
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [comboData, setComboData] = useState('');
    const [error, setError] = useState('');
    const [image, setImage] = useState('');
    const [inputValue, setInputValue] = useState({name:'', weight:'', count:'', price:'', image:'', category: []});

    useEffect(()=>{
      if(!userId){
        window.location ='/';
      }
    },[]);

    const getComboDetails = async () => {
        try{
          const response = await axios.get('https://demo.sninfoserv.com/api/combo/');
          //console.log(response.data);
          if(response.data.status === 'success'){
            setComboData(response.data);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getComboDetails();
    },[]);

    const handleInputChange = (e) => {
        const { name, value } = e.target; 
        setInputValue((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
      };

    const addCombo = async (e) =>{
        e.preventDefault();
        const formData = new FormData();
        formData.append('image', image);
        formData.append('name', inputValue.name);
        formData.append('weight', inputValue.weight);
        formData.append('count', inputValue.count);
        formData.append('price', inputValue.price);
        formData.append('category', [inputValue.category]);
        try{
            const response = await axios.post('https://demo.sninfoserv.com/api/combo/', formData);
            //console.log(response.data);
            if(response.data.status === 'success'){
                getComboDetails();
                alert('Combo Added!');
                setInputValue({name:'', weight:'', count:'', price:'', image:'', category: []})
            }else{
                setError(response.data.message);
            }
        }catch(error){
            setError(error.message);
        }
    };

    const deleteCombo = async (id) => {
        try{
          const response = await axios.post('https://demo.sninfoserv.com/api/combo/delete/', {cId: id});
          console.log(response.data);
          if(response.data.status === 'success'){
            getComboDetails();
            alert('Combo Removed!');
          }else{
            setError(response.data.message);
          }   
        }catch(error){
            setError(error.message);
        }
    };

    const addCategory = (category) => {
        setInputValue((prevValue) => {
            if (prevValue.category.includes(category)) {
                return {
                    ...prevValue,
                    category: prevValue.category.filter(item => item !== category)
                };
            } else {
                return {
                    ...prevValue,
                    category: [...prevValue.category, category]
                };
            }
        });
    };


    return (
        <> 
            <Sidebar />
            <div class="body-wrapper">
                <Header />
                <div class="body-wrapper-inner">
                <div class="container-fluid">
                  <div class='row'>
                    <div class="col-md-12 mt-2">
                        <div class="card">
                            <div class="card-body">
                            <h5 class="card-title">Add New Combo Offer</h5>
                                <form onSubmit={addCombo}>
                                    <div class="row mt-4">

                                        <div class="col-md-4 mt-2">
                                            <label class="form-label">Combo Name</label>
                                            <input type="text" class="form-control" name="name" value={inputValue.name} onChange={handleInputChange} required />
                                        </div>

                                        <div class="col-md-2 mt-2">
                                            <label class="form-label">Item Weight</label>
                                            <select class="form-control" name="weight" value={inputValue.weight} onChange={handleInputChange} required>
                                            <option selected hidden disabled value=''>Select a Weight</option>
                                                <option value='250'>250g</option>
                                                <option value='500'>500g</option>
                                               
                                            </select>
                                        </div>

                                        <div class="col-md-2 mt-2">
                                            <label class="form-label">Items Count</label>
                                            <input type="number" class="form-control" name="count" value={inputValue.count} onChange={handleInputChange} required />
                                        </div>

                                        <div class="col-md-2 mt-2">
                                            <label class="form-label">Combo Price</label>
                                            <input type="number" class="form-control" name="price" value={inputValue.price} onChange={handleInputChange} required />
                                        </div>

                                        <div class="col-md-2 mt-2">
                                            <label class="form-label">Combo Image</label>
                                            <input type="file" id="imageInput" accept="image/*" class="form-control" onChange={handleImageChange} required />
                                        </div>

                                        <label class="form-label mt-3">Select Category</label>

                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Sweets') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Sweets')}>Sweets</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Namkeen') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Namkeen')}>Namkeen</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Savouries') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Savouries')}>Savouries</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Podis') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Podis')}>Podis</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Fryums') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Fryums')}>Fryums</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Instant Rice Mix') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Instant Rice Mix')}>Instant Rice Mix</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Specials') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Specials')}>Specials</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('Pickles') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('Pickles')}>Pickles</button>
                                        </div>
                                        <div class="col-md-3 mt-2">
                                          <button type="button" class={`btn ${inputValue.category.includes('NRI Recomends') ? 'btn-warning' : 'btn-light'} w-100`} onClick={() => addCategory('NRI Recomends')}>NRI Recomends</button>
                                        </div>

                                        <div class="col-md-12 col-12 mt-4 " align='center'>
                                            <button type="submit" class='btn btn-success'>Add Combo</button>
                                        </div>

                                    </div>
                                </form>
                                <p class="text-danger text-center">{error.length > 0 && error}</p>
                            </div>
                        </div>
                    </div>
                  </div>

                  <div class="row">

                    {comboData && comboData.data && comboData.data.map((items, index)=>
<>
                    <div class="col-md-3 mt-2">
                        <div class="card">
                            <img src={`https://demo.sninfoserv.com/api/combo/images/${items.comboImage}`} class="card-img-top" alt="..." />
                            <span 
                                className="position-absolute"
                                style={{
                                    right: '20px',
                                    top: '8%',
                                    transform: 'translateY(-50%)',
                                    cursor: 'pointer',
                                    pointerEvents: 'auto',
                                    borderRadius: '50%',
                                    fontSize: '20px',
                                    color: '#333',
                                    borderRadius: '50%',
                                    background : '#fff',
                                    padding: '3px 8px',
                                    paddingTop: '6px',
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.5)'
                                }}
                            >
                                <iconify-icon icon="ic:round-delete" onClick={() => deleteCombo(items.comboId)} ></iconify-icon>
                            </span>
                            <div class="card-body">
                            <h5 class="card-title" style={{ fontWeight: 'bold' }} >{items.comboPack}</h5>
                                <p class="card-text">
                                {items.comboWeight}g x {items.comboCount}
                                    
                                     <span
                                        className="position-absolute"
                                        style={{
                                            right: '20px',
                                            top: '89%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            pointerEvents: 'auto',
                                        }}
                                    >
                                        <b class='text-dark'>₹ {Number(items.comboPrice).toFixed(2)}</b>
                                        
                                    </span>
                                </p>
    
                            </div>
                        </div>
                    </div>


                     {/* <div class="col-lg-3 d-flex align-items-strech">
                         <div class="card w-100">
                             <div class="card-body">
                                 <div class="d-sm-flex d-block align-items-center justify-content-between mb-9">
                                     <div class="mb-3 mb-sm-0">
                                         <h5 class="card-title fw-semibold" style={{ paddingRight: '20px' }} >{items.comboPack} 
                                             <span 
                                                className="position-absolute text-danger"
                                                style={{
                                                    right: '30px',
                                                    top: '10%',
                                                    transform: 'translateY(-50%)',
                                                    cursor: 'pointer',
                                                    pointerEvents: 'auto',
                                                 }}
                                            >
                                                <iconify-icon icon="ic:round-delete" ></iconify-icon>
                                            </span>
                                        </h5>
                                    </div>
                                </div>
                                <img src="https://jampani.lightndezine.com/assets/products/SWEETS.jpg" width="100%" />
                                <p class='mt-2'>
                                    
                                    <b class='text-dark'>{items.comboCategory} </b><br />
                                    {items.comboWeight}g x {items.comboCount} 
                                    <span
                                        className="position-absolute"
                                        style={{
                                            right: '20px',
                                            top: '82%',
                                            transform: 'translateY(-50%)',
                                            cursor: 'pointer',
                                            pointerEvents: 'auto',
                                        }}
                                    >
                                        <b class='text-dark'>₹ {Number(items.comboPrice).toFixed(2)}</b>
                                    </span>
                                    
                                </p>
                            </div>
                        </div>
                    </div> */}
                    </>
                    )}

                    {/* <div class="col-md-3 mt-2">
                        <div class="card">
                            <img src="assets/images/backgrounds/add.webp" class="card-img-top" alt="..." />
                            <div class="card-body">
                              <h5 class="card-title text-center" style={{ fontWeight: 'bold' }} >Add New Combo</h5>
                              <a href='/add-combo' class='btn btn-primary w-100'>Add New</a>
                            </div>
                        </div>
                    </div> */}

                  </div>

                  <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>
            
                </div>
              </div>
            </div>
        </>
    );
}
export default App;