import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import { Paginator } from 'primereact/paginator';
import Header from './header';
import Sidebar from './sidebar';
import '../App.css'
ReactSession.setStoreType("localStorage");

function App() {
    
    const userId = sessionStorage.getItem('jmpiadminUserId');
    const [productId, setProductId] = useState(null);
    const [productData, setProductData] = useState(null);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState({name:'', type:'', cat:'', sub_cat:'', tprice:'', fprice:'', image1:'', image2:'', des:'', gst: ''});
    const [image, setImage] = useState(null);
    const [image1, setImage1] = useState(null);

    useEffect(()=>{
        if(!userId){
          window.location ='/';
        }
    },[]);

    const extractProductId = () => {
        const url = window.location.href; 
        const queryString = url.split('?')[1] || ''; 
        const params = new URLSearchParams(queryString);
        const product = params.get('id');
        if(product){
          setProductId(product);    
        }
    };

    useEffect(() => {
        extractProductId();
    }, [productId]);

    const handleInputChange = (e) => {
        const { name, value } = e.target; 
        setInputValue((prevData) => ({
          ...prevData,
          [name]: value,
        }));
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleImageChange1 = (e) => {
        setImage1(e.target.files[0]);
    };

    const editProduct = async (e) =>{
      e.preventDefault();
      const formData = new FormData();
      formData.append('name', inputValue.name);
      formData.append('cat', inputValue.cat);
      formData.append('sub_cat', inputValue.sub_cat);
      formData.append('type', inputValue.type);
      formData.append('tprice', inputValue.tprice);
      formData.append('fprice', inputValue.fprice);
      formData.append('des', inputValue.des);
      formData.append('gst', inputValue.gst);
      if(image !== null){
        formData.append('image1', image);
      }
      if(image1 !== null){
        formData.append('image2', image1);
      }

      try{
        const response = await axios.post('https://demo.sninfoserv.com/api/products/add-product/', formData);
        //console.log(response);
        if(response.data.status === 'success'){
            window.location ='/products';
        }
      }catch(error){

      }
    };

    return (
        <> 
        <Sidebar />
            <div class="body-wrapper">
            <Header />
                <div class="body-wrapper-inner">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12 d-flex align-items-stretch">
                                <div class="card w-100">
                                    <div class="card-body p-4">
                                        <h5 class="card-title fw-semibold mb-4">Add New Product</h5>
                                    <form onSubmit={editProduct} enctype="multipart/form-data">
                                    <div class="row mt-4">

                                        <div class="col-md-6 mt-2">
                                            <label class="form-label">Product</label>
                                            <input type="text" class="form-control" name="name" value={inputValue.name} onChange={handleInputChange}  />
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">Type</label>
                                            <select class="form-control" name="type" value={inputValue.type} onChange={handleInputChange} >
                                                <option selected hidden value=''>Select Type</option>
                                                <option>NRI Recomends</option>
                                                <option>Pickles</option>
                                                <option>Sweets</option>
                                                <option>Combos</option>
                                                <option>Namkeen</option>
                                                <option>Savouries</option>
                                                <option>Podis</option>
                                                <option>Fryums</option>
                                                <option>Instant Rice Mix</option>
                                                <option>Specials</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">Category</label>
                                            <select class="form-control" name="cat" value={inputValue.cat} onChange={handleInputChange} >
                                                <option selected hidden value=''>Select Category</option>
                                                <option>Veg Pickles</option>
                                                <option>Non Veg Pickles</option>
                                                <option>Ghee Sweets</option>
                                                <option>Kaju Sweets</option>
                                                <option>Sugar-Free Sweets</option>
                                                <option>Traditional Sweets</option>
                                                <option>Kalakand Sweets</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">Category</label>
                                            <select class="form-control" name="sub_cat" value={inputValue.sub_cat} onChange={handleInputChange} >
                                                <option selected hidden value=''>Select Sub-Category</option>
                                                <option>All Veg Pickles</option>
                                                <option>Mutton Pickles</option>
                                                <option>Chicken Pickles</option>
                                                <option>Fish Pickles</option>
                                                <option>Prawn Pickles</option>
                                                <option>Mixed Non-Veg Pickles</option>
                                            </select>
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">250g Price</label>
                                            <input type="number" class="form-control" name="tprice" value={inputValue.tprice} onChange={handleInputChange} />
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">500g Price</label>
                                            <input type="number" class="form-control" name="fprice" value={inputValue.fprice} onChange={handleInputChange} />
                                        </div>

                                        <div class="col-md-3 mt-2">
                                            <label class="form-label">GST %</label>
                                            <input type="number" class="form-control" name="gst" value={inputValue.gst} onChange={handleInputChange} />
                                        </div>

                                        <div class="col-md-6 mt-2">
                                            <label class="form-label">Image 1</label>
                                            <input type="file" id="imageInput" accept="image/*" class="form-control" onChange={handleImageChange} />
                                        </div>

                                        <div class="col-md-6 mt-2">
                                            <label class="form-label">Image 2</label>
                                            <input type="file" id="imageInput" accept="image/*" class="form-control" onChange={handleImageChange1} />
                                        </div>

                                        <div class="col-md-12 mt-2">
                                            <label class="form-label">Description</label>
                                            <textarea class="form-control" rows='7' name='des' onChange={handleInputChange} value={inputValue.des}></textarea>
                                        </div>

                                        <div class="col-md-12 col-12 mt-4 " align='center'>
                                            <button type="submit" class='btn btn-success'>Add Product</button>
                                        </div>

                                    </div>
                                </form>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>

                    </div>
                </div>
            </div>
        </>
    );
}
export default App;