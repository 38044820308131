import React, { useEffect, useState } from 'react';
import axios, { toFormData } from 'axios';
import { ReactSession } from 'react-client-session';
import Header from './header';
import Sidebar from './sidebar';
import '../App.css';
ReactSession.setStoreType("localStorage");
function App() {

    const userId = sessionStorage.getItem('jmpiadminUserId'); 
    const [orderData, setOrderData] = useState('');
    const [orderDetails, setOrderDetails] = useState('');
    const [billingData, setBillingData] = useState('');
    const [shippingData, setShippingData] = useState('');
    const [statusData, setStatusData] = useState('');
    const [orderId, setOrderId] = useState('');
    const [error, setError] = useState('');

    const [orderStatus, setOrderStatus] = useState('');
    const [orderTrack, setOrderTrack] = useState('');
    const [orderCourier, setOrderCourier] = useState('');

    useEffect(()=>{
      if(!userId){
        window.location ='/';
      }
    },[]);

    const extractProductId = () => {
        const url = window.location.href; 
        const queryString = url.split('?')[1] || ''; 
        const params = new URLSearchParams(queryString);
        const product = params.get('id');
        if (product) {
          setOrderId(product);    
        }
    };

    useEffect(() => {
        extractProductId();
    }, [orderId]);


    const getOrderDetails = async () => {
        try{
          const response = await axios.post('https://demo.sninfoserv.com/api/orders/get-order/', {orderId: orderId});
          console.log(response.data);
          if(response.data.status === 'success'){
            setOrderData(response.data.orderData);
            setOrderDetails(response.data.orderDetails);
            setBillingData(response.data.billingData);
            setShippingData(response.data.shippingData);
            setStatusData(response.data.orderStatus);
            setOrderStatus(response.data.orderData[0].orderStatus);
            setOrderCourier(response.data.orderData[0].courierPartner);
            setOrderTrack(response.data.orderData[0].trackingId);
          }
        }catch (error) {
        setError({ error: error.message });
        }
    };

    useEffect(() => {
        getOrderDetails();
    },[orderId]);
    
    const changeStatus = async (e) => {
      e.preventDefault();
      const formData = {
        orderId : orderId,
        status : orderStatus
      }
      try{
        const response = await axios.post('https://demo.sninfoserv.com/api/orders/status/', formData );
        if(response.data.status === 'success'){
          //alert('Order Updated!');
          getOrderDetails();
        }
      }catch(error){
      }
    };

    const updateTrack = async (e) => {
      e.preventDefault();
      const formData = {
        orderId : orderId,
        trackingId : orderTrack,
        courier : orderCourier
      }
      try{
        const response = await axios.post('https://demo.sninfoserv.com/api/orders/tracking/', formData );
        if(response.data.status === 'success'){
          //alert('Tracking Updated!');
          getOrderDetails();
        }
      }catch(error){

      }
    };



    return (
      <>
      <Sidebar />
        <div class="body-wrapper">
         <Header />
         <div class="body-wrapper-inner">
          <div class="container-fluid">

            <div class="row">

              <div class="col-lg-4 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Orders Details</h5>
                    <tr>
                        <td><p class="card-text">Order ID &nbsp;</p></td>
                        <td><p class="card-text"> : {orderId}</p></td>
                    </tr>
                    <tr>
                        <td><p class="card-text">Date &nbsp;</p></td>
                        <td><p class="card-text"> : {orderData.length > 0 ? orderData[0].time : 'Loading...'}</p></td>
                    </tr>
                    <tr>
                        <td><p class="card-text">Amount &nbsp;</p></td>
                        <td><p class="card-text"> : ₹ {orderData.length > 0 ? Number(orderData[0].grandTotal).toFixed(2) : 'Loading...'}</p></td>
                    </tr>
                    <tr>
                        <td><p class="card-text">Ship To &nbsp;</p></td>
                        <td><p class="card-text"> : {shippingData !== '' ? shippingData.country : 'Loading...'}</p></td>
                    </tr>
                    <tr>
                        <td><p class="card-text">Items &nbsp;</p></td>
                        <td><p class="card-text"> : {orderData.length > 0 ? orderData[0].Items : 'Loading...'}</p></td>
                    </tr>
                    
                  </div>
                </div>
              </div>

              <div class="col-lg-4 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Billing Details</h5>
                    {billingData ? (
                      <>
                      <p>
                        <b>{billingData.fName} {billingData.lName}</b><br />
                        {billingData.adrs1}, {billingData.adrs2} <br />
                        {billingData.town}, {billingData.state}<br />
                        {billingData.country} - {billingData.zip}
                      </p> 
                      <p>
                        {billingData.phone}<br />
                        {billingData.email}
                      </p>
                      </>
                      ):(
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>

              <div class="col-lg-4 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Shipping Details</h5>
                    {shippingData ? (
                      <>
                      <p>
                        <b>{shippingData.fName} {shippingData.lName}</b><br />
                        {shippingData.adrs1}, {shippingData.adrs2} <br />
                        {shippingData.town}, {shippingData.state}<br />
                        {shippingData.country} - {shippingData.zip}
                      </p>
                      <p>
                        {shippingData.phone}<br />
                        {shippingData.email}
                      </p>
                      </>
                      ):(
                        <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>

              <div class="col-lg-12 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Order Items</h5>

                    <div class="table-responsive" data-simplebar>
                      <table class="table text-nowrap align-middle table-custom mb-0">
                        <thead>
                          <tr>
                            <th scope="col" class="text-dark fw-normal ps-0">S.No</th>
                            <th scope="col" class="text-dark fw-normal">Item ID</th>
                            <th scope="col" class="text-dark fw-normal">Item</th>
                            <th scope="col" class="text-dark fw-normal">Price</th>
                            <th scope="col" class="text-dark fw-normal">GST</th>
                            <th scope="col" class="text-dark fw-normal">Qty</th>
                            <th scope="col" class="text-dark fw-normal">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                        {orderDetails && orderDetails.length > 0 && orderDetails ? (
                          orderDetails.map((product, index) => (
                            <>
                            <tr key={index}>
                              <td>{index+1}</td>
                              <td>{product.productId}</td>
                              <td class="text-left">{product.productName} - {product.productWeight}g</td>
                              <td>₹ {Number(product.productPrice - (product.productPrice - (product.productPrice / ((product.productGst/100)+1))) ).toFixed(2)}</td>
                              <td>₹ {Number(product.productPrice - (product.productPrice / ((product.productGst/100)+1)) ).toFixed(2)}</td>
                              <td>{product.productQty}</td>
                              <td className="text-right">₹ {Number(product.productPrice * product.productQty).toFixed(2)}</td>
                            </tr>
                            {product.comboItems.length > 0 &&
                              <tr>
                                <td colspan="2"></td>
                                <td colspan="5" class='text-left'>
                                  <small>{product.comboItems.map((items, index) => index === 0 ? ''+ items.item :', ' + items.item )}</small>
                                </td>
                              </tr>
                            }
                          </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7">Loading</td>
                          </tr>
                        )}
                            <tr>
                                <td colSpan={7} style={{padding: '10px'}}> <hr></hr></td>
                            </tr>
                            
                            <tr>
                                <th colspan='6' class="text-right">Subtotal :</th>
                                    <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].subTotal - orderData[0].gst).toFixed(2) : 'Loading...'}</td>
                                </tr>
                            <tr>
                                <th colspan='6' class="text-right">Tax :</th>
                                <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].gst).toFixed(2) : 'Loading...'}</td>
                            </tr>
                            <tr>
                                <th colspan='6' class="text-right">Shipping :</th>
                                <td class="text-right">₹ {orderData.length > 0 ? Number(orderData[0].shippingCharge).toFixed(2) : 'Loading...'}</td>
                            </tr>
                            <tr>
                                <th colspan='6' class="text-right">Discount :</th>
                                <td class="text-right">- ₹ {orderData.length > 0 ? Number(orderData[0].discount).toFixed(2) : 'Loading...'}</td>
                            </tr>
                               
                            <tr class="total">
                                <th colspan='6' class="border-no text-right">Grand Total :</th>
                                <td class="border-no text-right">₹ {orderData.length > 0 ? Number(orderData[0].grandTotal).toFixed(2) : 'Loading...'}</td>
                            </tr>
                        </tbody>
                      </table>
                    </div>

                    </div>
                </div>
              </div>

              <div class="col-lg-12 d-flex align-items-stretch">
                <div class="card w-100">
                  <div class="card-body p-4">
                    <h5 class="card-title fw-semibold mb-4">Update Order</h5>
                    <div class="row">
                      <div class="col-lg-4 mt-4">
                      <label class="form-label">Tracking Data</label>
                        <ul>
                          {statusData && statusData.map((item, index) =>
                            <li>{item.date} - {item.status}</li>
                          )}
                        </ul>
                      </div>
                      <div class="col-lg-3 mt-4">
                        <form onSubmit={changeStatus}>
                          <label class="form-label">Change Status</label>
                           <select class="form-control" name="type" value={orderStatus} onChange={(e)=> setOrderStatus(e.target.value)}>
                              <option selected hidden value=''>Select Order Status</option>
                              <option>Accepted</option>
                              <option>Shipped</option>
                              <option>Delivered</option>
                              <option>Cancelled</option>
                              <option>Holded</option>
                              <option>Qued</option>
                              <option hidden>Order Placed</option>
                            </select>
                            <button type="submit" class="btn btn-primary mt-2 w-100" >Save Changes</button>
                        </form>
                      </div>

                      <div class="col-lg-5 mt-4">
                        {orderData?.length > 0 ? orderData[0]?.trackingId?.length > 0 ? 
                          <div class="row">
                            <div class="col-lg-6">
                              <label class="form-label">Tracking ID</label>
                              <p>{orderData[0].trackingId}</p>
                            </div>
                            <div class="col-lg-6">
                              <label class="form-label">Courier Partner</label>
                              <p>{orderData[0].courierPartner}</p>
                            </div>
                          </div>
                         :

                        <form onSubmit={updateTrack}>
                          <div class="row">
                            <div class="col-lg-6">
                              <label class="form-label">Tracking ID</label>
                              <input type="text" class="form-control" value={orderTrack}  onChange={(e)=> setOrderTrack(e.target.value)} />
                            </div>
                            <div class="col-lg-6">
                              <label class="form-label">Courier Partner</label>
                              <input type="text" class="form-control" value={orderCourier} onChange={(e)=> setOrderCourier(e.target.value)} />
                            </div>
                          </div>
                          <button type="submit" class="btn btn-success mt-2 w-100" >Update Tracking Details</button>
                        </form>
                        :'Loading'}
                      </div>

                    </div>
                     
                  </div>
                </div>
              </div>

            </div>

            
            
            <div class="py-6 px-6 text-center">
              <p class="mb-0 fs-4">Design and Developed by <a href="mailto:bizzoters@gmail.com" target="_blank"
                class="pe-1 text-primary text-decoration-underline">Bizzoters</a></p>
            </div>

            <div id="revenue-forecast" style={{display: 'none'}}></div>
            <div id="total-income" style={{display: 'none'}}></div>
            
            
          </div>
        </div>
      </div>
      </>
    );
  }
  
  export default App;
  